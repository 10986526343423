<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6 offset-3">
        <apexchart :options="chartOptions" :series="series" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EquipmentService from "../../../services/equipment.service";
import { Measures } from "@/utils/influx";
import { Operations } from "@/utils/influx";
export default {
  name: "StatusGraph",
  props: {
    machineId: String,
    startDate: Date,
    endDate: Date,
  },
  data() {
    const xlabels = [
      "Totale Ore Motore (h)",
      "Idle (h)",
      "Run (h)",
      "Power Off (h)",
    ];
    const colors = ["#E57373", "#AB47BC", "#80CBC4", "#F57C00"];
    return {
      series: [
        {
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: true,
            tools: {
              download: false, // <== line to add
            },
          },
        },
        colors: colors,
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: xlabels,
          labels: {
            style: {
              colors: colors,
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(2);
            },
          },
        },
      },
    };
  },
  async created() {
    await this.updateChartData();
  },
  methods: {
    async getMachineTotalHours() {
      let queryDTO = {
        dataRangeStart: this.startDate,
        dataRangeEnd: this.endDate,
        measures: [Measures.Engine_Hours],
        tags: {
          machineId: [this.machineId],
        },
        operation: Operations.Last,
      };

      return EquipmentService.getData(queryDTO).then((response) => {
        if (response.data.length == 0) {
          return 0;
        }
        return response.data[0].fields.measureValue;
      });
    },

    async getMachineIdleHours() {
      let queryDTO = {
        dataRangeStart: this.startDate,
        dataRangeEnd: this.endDate,
        measures: [Measures.Engine_Idle_Hours],
        tags: {
          machineId: [this.machineId],
        },
        operation: Operations.Last,
      };

      return EquipmentService.getData(queryDTO).then((response) => {
        if (response.data.length == 0) {
          return 0;
        }
        return response.data[0].fields.measureValue;
      });
    },

    async getMachineRunHours() {
      let queryDTO = {
        dataRangeStart: this.startDate,
        dataRangeEnd: this.endDate,
        measures: [Measures.Engine_Run_Hours],
        tags: {
          machineId: [this.machineId],
        },
        operation: Operations.Last,
      };

      return EquipmentService.getData(queryDTO).then((response) => {
        if (response.data.length == 0) {
          return 0;
        }
        return response.data[0].fields.measureValue;
      });
    },
    async updateChartData() {
      let totalHours = await this.getMachineTotalHours();
      let runHour = await this.getMachineRunHours();
      runHour = runHour / 3600;
      let idleHours = await this.getMachineIdleHours();
      idleHours = idleHours / 3600;
      let powerOffHours = totalHours - runHour;

      //Empty Data
      this.series[0].data.splice(0, this.series[0].data.length);

      /*console.log("totalHours", totalHours);
      console.log("runHour", runHour);
      console.log("idleHours", idleHours);
      console.log("powerOffHours", powerOffHours);*/

      this.series[0].data.push(totalHours);
      this.series[0].data.push(idleHours);
      this.series[0].data.push(runHour);
      this.series[0].data.push(powerOffHours);
    },
  },
  computed: {
    changeData() {
      const { startDate, endDate } = this;
      return {
        startDate,
        endDate,
      };
    },
  },
  watch: {
    changeData: {
      deep: true,
      handler: async function () {
        await this.updateChartData();
      },
    },
  },
};
</script>
