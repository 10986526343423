<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-card-title class="text-h6 text-md-h5 text-lg-h4"
          >Companies</v-card-title
        >
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cerca"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn color="info" @click="goToCreateCompany">
          Add Company<v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-0">
          <v-data-table
            v-model:items-per-page="itemsPerPage"
            :items="companies"
            :headers="headers"
            :items-per-page="10000"
            :search="search"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <table>
                <tr>
                  <td>
                    <v-btn
                      icon
                      title="Modifica"
                      @click="goToEditCompany(item.raw.id)"
                    >
                      <v-icon color="info">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn icon title="Elimina" @click="doConfirmDelete(item)">
                      <v-icon color="info">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="confirmDeleteDialogVisible" max-width="350">
          <v-card>
            <v-card-title
              class="text-uppercase font-weight-regular py-2 my-2 panelheader"
              >Confirm
            </v-card-title>
            <v-card-text class="text-h6">
              Delete company <b>{{ selectedCompany.name }}</b> ?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="grey darken-1"
                @click="confirmDeleteDialogVisible = false"
                small
                >No</v-btn
              >
              <v-btn color="info" @click="confirmDelete" small>Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import equipmentDataService from "../../services/equipmentData.service";
import companyService from "../../services/company.service";

export default {
  name: "CompaniesPage",
  title: "Companies",
  data() {
    return {
      itemsPerPage: 15,
      search: "",
      companies: [],
      headers: [
        {
          title: "",
          key: "actions",
          class: "text-uppercase",
          sortable: false,
        },
        { title: "Name", key: "name", class: "text-uppercase" },
        { title: "BusinessName", key: "businessName", class: "text-uppercase" },
        { title: "Code", key: "code", class: "text-uppercase" },
        {
          title: "VatNumber",
          key: "vatNumber",
          class: "text-uppercase",
        },
        {
          title: "Address",
          key: "address",
          class: "text-uppercase",
          sortable: false,
        },
        { title: "Country", key: "country", class: "text-uppercase" },
        { title: "Role", key: "role", class: "text-uppercase" },
      ],
      selectedCompany: {},
      confirmDeleteDialogVisible: false,
    };
  },

  async mounted() {
    await this.updateCompaniesData();
    //console.log("Companies: ", this.companies);
  },
  methods: {
    doConfirmDelete(item) {
      //console.log("item:", item);
      this.selectedCompany = item.raw;
      this.confirmDeleteDialogVisible = true;
    },
    async updateCompaniesData() {
      this.companies = await equipmentDataService.getCompanies();
    },
    async confirmDelete() {
      try {
        //console.log("selectedCompany:", this.selectedCompany);
        let userCompany = await companyService.getCompanyByVatNumber(
          this.selectedCompany.vatNumber
        );

        if (userCompany) {
          //console.log("userCompany", userCompany);
          await companyService.deleteCompany(userCompany.id);
        }

        await equipmentDataService.deleteCompany(this.selectedCompany.id);
      } catch (ex) {
        console.error("Error delete company", ex);
      }
      this.confirmDeleteDialogVisible = false;
      await this.updateCompaniesData();
    },

    goToCreateCompany() {
      //console.log("goToCreateCompany");
      this.$router.push({ path: "/createCompany" });
      return;
    },
    goToEditCompany(companyId) {
      //console.log("companyId", companyId);
      this.$router.push({ path: "/editCompany/" + companyId });
      return;
    },
  },
};
</script>
