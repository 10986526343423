<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="text-uppercase">
        <v-row>
          <v-col cols="6">
            {{ companyId ? "Edit company" : "Create company" }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="editCardClass">
        <v-form ref="manageCompanyForm" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="company.name"
                label="Name"
                :rules="[rules.required,rules.min]"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="company.businessName"
                label="Business Name"
                maxlength="100"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="company.code"
                label="Code"
                :rules="[rules.required,rules.min]"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="company.vatNumber"
                label="Vat Number"
                maxlength="11"
                :rules="[rules.required,rules.vatNumber]"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="company.role"
                label="Role"
                :items="['Manufacturer', 'Owner']"
                :rules="[rules.required]"
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="9">
              <v-text-field
                v-model="company.address"
                label="Address"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="company.country"
                label="Country"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="grey darken-1"
          class="pa-2 ma-2"
          @click="discard"
          flat
          >Cancel</v-btn
        >
        <v-btn
          color="success"
          :disabled="!valid"
          class="pa-2 ma-2"
          @click="validate"
          medium
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import equipmentDataService from "../../services/equipmentData.service";
import companyService from "../../services/company.service";

export default {
  name: "ManageCompanyPage",
  title: "ManageCompany",
  props: {
    companyId: null,
  },
  data() {
    return {
      userRoles: null,
      valid: true,
      company: {},
      show1: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 3) || "Min 3 characters",
        vatNumber: (v) => /[0-9]{11}/.test(v) || "Vat number must be valid",
      },
    };
  },
  async mounted() {
    if (this.companyId) {
      this.company = await equipmentDataService.getCompanyById(this.companyId);
      //console.log("company", this.company);
    }
  },

  methods: {
    async validate() {
      if (this.$refs.manageCompanyForm.validate()) {
        //console.log("company:", this.company);
        await equipmentDataService.saveCompany(this.company);
        let companyToSave= await this.getUserCompanyToSave();
        //console.log("companyToSave",companyToSave);
        await companyService.save(companyToSave);
        this.$router.back();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    discard() {
      this.$router.back();
    },
    async getUserCompanyToSave() {
      let userCompany = await companyService.getCompanyByVatNumber(
        this.company.vatNumber
      );
      //console.log("userCompany", userCompany);

      let companyToSave = new Object();
      if(userCompany){
        companyToSave.id= userCompany.id;
        companyToSave.vatCountryCode= userCompany.vatCountryCode;
        companyToSave.companyRoles= userCompany.companyRoles;
      }
      companyToSave.code = this.company.code;
      companyToSave.name = this.company.name;
      companyToSave.vatNumber = this.company.vatNumber;

      return companyToSave;
    },
  },
};
</script>
