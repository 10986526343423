<template>
  <v-card width="350" class="ma-2 rounded-xl" :to="machineUrl">
    <v-card-title class="text-center bg-indigo-darken-2"
      >{{ machine.name }}</v-card-title
    >
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <span>Equipment Model: </span>
            <span class="text-primary">{{ machine.model }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span>Equipment Brand: </span>
            <span class="text-primary">{{ machine.brand }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span>Serial: </span>
            <span class="text-primary">{{ machine.deviceSerial }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span>Latest Data: </span>
            <span class="text-primary">{{ machineLastTime }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <span>Acceso: </span>
            <v-icon
              size="large"
              :color="machine.isStarted ? 'green' : 'red'"
              icon="mdi-moon-full"
            />
          </v-col>
          <v-col cols="6">
            <span>Operatore: </span>
            <v-icon size="large" :color="machine.hasOperator ? 'green' : 'red'" icon="mdi-moon-full" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <apexchart type="pie" :options="chartOptions" :series="series" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: "MachineCard",
  props: {
    machine: Object,
  },
  data() {
    return {
      series: [
        this.machine.hRun,
        this.machine.hIdle,
        this.machine.powerOff,
      ],
      chartOptions: {
        chart: {
          type: "pie",
        },
        colors: ['#A5D6A7', '#90CAF9', '#E53935'],
        labels: [ "Run", "Idle", "Off"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 20,
                heigth: 100,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    machineUrl: {
      get() {
        return "machine/" + this.machine.id;
      },
    },
    machineLastTime: {
      get() {
        return moment(this.machine.measureData).format("DD/MM/YYYY");
      },
    },
  },
};
</script>
