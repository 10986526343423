import axios from "axios";
import getEnv from "@/utils/env";
import authHeader from "./auth-header";

const COMPANIES_URL = getEnv("VUE_APP_USERS_MANAGER_URL") + "companies/";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET",
    ...authHeader(),
  },
};
const reqObj = {
  offset: 0,
  limit: 10000,
  filters: [],
};

class CompanyService {
  browseCompanies() {
    return axios
      .post(COMPANIES_URL + "browse", reqObj, config)
      .then((response) => {
        // If request is good...
        //console.log("browseCompanies", response.data);
        return response;
      })
      .catch((error) => {
        console.log("error " , error);
      });
  }

  getCompanyById(companyId) {
    return axios
      .get(COMPANIES_URL + `${companyId}`, config)
      .then((response) => {
        // If request is good...
        //console.log("getCompanyById", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  getCompanyByVatNumber(vatNumber) {
    return axios
      .get(COMPANIES_URL + `getByVatnumber/${vatNumber}`, config)
      .then((response) => {
        // If request is good...
        //console.log("getCompanyById", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  save(company) {
    let body = JSON.stringify(company);
    let companyId = company.id ? company.id : "";
    return axios
      .post(COMPANIES_URL + companyId, body, config)
      .then((response) => {
        // If request is good...
        //console.log("saveCompany", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  deleteCompany(companyId) {
    return axios
      .delete(COMPANIES_URL + `${companyId}`, config)
      .then((response) => {
        // If request is good...
        //console.log("deleteCompany", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }
}

export default new CompanyService();
