<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-card-title class="text-h6 text-md-h5 text-lg-h4">Users</v-card-title>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cerca"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn color="info" @click="goToCreateUser">
          Create<v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-0">
          <v-data-table
            v-model:items-per-page="itemsPerPage"
            :items="users"
            :headers="headers"
            :items-per-page="10000"
            :search="search"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.activatedAt`]="{ item }">
              <div>
                <v-icon
                  v-if="
                    item.columns.activatedAt &&
                    item.columns.activatedAt !== null
                  "
                  color="success"
                  >mdi-account-check</v-icon
                >
                <v-btn icon v-else large @click="doConfirmActivate(item)">
                  <v-icon color="warning" large>mdi-account-lock</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:[`item.groups`]="{ item }">
              <div v-for="gr in item.columns.groups" :key="gr.id">
                {{ gr.code }}
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <table>
                <tr>
                  <td>
                    <v-btn
                      icon
                      title="Modifica"
                      @click="goToEditUser(item.raw.id)"
                    >
                      <v-icon color="info">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn v-if="loggedUser.id != item.raw.id" icon title="Elimina" @click="doConfirmDelete(item)">
                      <v-icon color="info">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="confirmDeleteDialogVisible" max-width="350">
          <v-card>
            <v-card-title
              class="text-uppercase font-weight-regular py-2 my-2 panelheader"
              >Confirm
            </v-card-title>
            <v-card-text class="text-h6">
              Delete user <b>{{ selectedUser.username }}</b> ?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="grey darken-1"
                @click="confirmDeleteDialogVisible = false"
                small
                >No</v-btn
              >
              <v-btn color="info" @click="confirmDelete" small>Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="confirmActivateDialogVisible" max-width="450">
          <v-card>
            <v-card-title
              class="text-uppercase font-weight-regular py-2 my-2 panelheader"
            >
              Confirm
            </v-card-title>
            <v-card-text class="text-h6">
              Activate user <b>{{ selectedUser.username }}</b> ?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="grey darken-1"
                @click="confirmActivateDialogVisible = false"
                small
                >No</v-btn
              >
              <v-btn color="info" @click="confirmActivate" small>Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import usersService from "../../services/user.service";
export default {
  name: "UsersPage",
  title: "Users",
  data() {
    return {
      loggedUser:{},
      itemsPerPage: 15,
      search: "",
      users: [],
      headers: [
        {
          title: "",
          key: "actions",
          class: "text-uppercase",
          sortable: false,
        },
        {
          title: "Active",
          key: "activatedAt",
          class: "text-uppercase",
          sortable: false,
        },
        { title: "Username", key: "username", class: "text-uppercase" },
        { title: "Surname", key: "lastName", class: "text-uppercase" },
        { title: "Name", key: "firstName", class: "text-uppercase" },
        {
          title: "Company",
          key: "company.name",
          class: "text-uppercase",
        },
        {
          title: "Groups",
          key: "groups",
          class: "text-uppercase",
          sortable: false,
        },
      ],
      selectedUser: {},
      confirmDeleteDialogVisible: false,
      confirmActivateDialogVisible: false,
    };
  },

  async mounted() {
    await this.updateUsersData();
    //console.log("Users: ", this.users);
    this.loggedUser=this.$store.state.auth.user.user;
    //console.log("loggedUser: ", this.loggedUser);
  },
  methods: {
    doConfirmActivate(item) {
      //console.log("item:", item.columns);
      this.selectedUser = item.raw;
      this.confirmActivateDialogVisible = true;
    },
    doConfirmDelete(item) {
      //console.log("item:", item);
      this.selectedUser = item.raw;
      this.confirmDeleteDialogVisible = true;
    },
    async updateUsersData() {
      await usersService.browseUsers().then(
        (response) => {
          this.users = response.data.resultList;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    async confirmDelete() {
      try {
        //console.log("selectedUser:", this.selectedUser);
        await usersService.deleteUser(this.selectedUser.id);
      } catch (ex) {
        console.error("Error delete user", ex);
      }
      this.confirmDeleteDialogVisible = false;
      await this.updateUsersData();
    },
    async confirmActivate() {
      try {
        //console.log("selectedUser:", this.selectedUser);
        await usersService.activateUser(this.selectedUser.id);
      } catch (ex) {
        console.error("Error activate user", ex);
      }
      this.confirmActivateDialogVisible = false;
      await this.updateUsersData();
    },
    goToCreateUser() {
      //console.log("goToCreateUser");
      this.$router.push({ path: "/createUser" });
      return;
    },
    goToEditUser(userId) {
      this.$router.push({ path: "/editUser/" + userId });
      return;
    },
  },
};
</script>
