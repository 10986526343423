<template>
  <v-container>
    <v-row>
      <v-col cols="12"> <map-component :machinePositions="machinePositions" /></v-col>
      <v-col cols="12"><machine-slide :machines="machines" /> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CompanyRole } from "@/utils/roles";
import { Measures } from "@/utils/influx";
import { Operations } from "@/utils/influx";

import EquipmentService from "../../services/equipment.service";
import equipmentDataService from "../../services/equipmentData.service";
import MachineSlide from "../equipment/MachineSlide.vue";
import MapComponent from "../equipment/MapComponent.vue";

export default {
  name: "HomePage",
  components: {
    MapComponent,
    MachineSlide,
  },
  data() {
    return {
      currentUser: {},
      userCompany: {},
      usersMachines: [],
      machines: [],
      machinePositions: [],
    };
  },

  async mounted() {
    this.currentUser = this.$store.state.auth.user.user;
    //console.log("currentUser",this.currentUser);
    let vatCompany =
      this.currentUser && this.currentUser.company
        ? this.currentUser.company.vatNumber
        : "";

    //console.log("vatCompany", vatCompany);

    if (vatCompany) {
      this.userCompany = await equipmentDataService.getCompanyByVatnumber(vatCompany);

      if (this.userCompany) {
        //console.log("userCompany", this.userCompany);
        if (CompanyRole.Manufacturer === this.userCompany.role) {
          this.usersMachines = await equipmentDataService.getAllMachineByDeviceManufacturerCompanyId(
            this.userCompany.id
          );
        } else if (CompanyRole.Owner === this.userCompany.role) {
          this.usersMachines = await equipmentDataService.getAllMachineByOwnerCompanyId(
            this.userCompany.id
          );
        }

        //console.log("machines", this.usersMachines);
      }
    }
    let userMachineIds = this.usersMachines.map((us) => us.influxMachineId).map(String);
    //console.log("userMachineIds", userMachineIds);
    let queryDTO = {
      tags: {
        machineId: userMachineIds,
      },
      measures: [
        Measures.Operator_Presence,
        Measures.Engine_Started,
        Measures.Longitude,
        Measures.Latitude,
        Measures.Engine_Idle_Hours,
        Measures.Engine_Run_Hours,
        Measures.Engine_Hours,
      ],
      operation: Operations.Last,
    };

    await EquipmentService.getSpecificMachinesData(queryDTO).then((response) => {
      let equipmentMachines = response.data;
      //console.log(equipmentMachine);
      this.fillMachinesAndPosition(equipmentMachines);
      this.reloadPage();
    });

    //console.log("machines",this.machines);
    //console.log("machinePositions",this.machinePositions);
  },

  methods: {
    fillMachinesAndPosition(equipmentMachines) {
      equipmentMachines.forEach((item) => {
        let userMachine = this.usersMachines.find((m) => {
          return m.influxMachineId == item.machineId;
        });

        //console.log("userMachine", userMachine);

        //machine data for slide
        let machine = new Object();
        machine.id = item.machineId;
        machine.name = userMachine ? userMachine.name : "N/A";
        machine.brand = userMachine ? userMachine.brand : "N/A";
        machine.model = userMachine ? userMachine.model : "N/A";
        machine.deviceName = userMachine ? userMachine.deviceName : "N/A";
        machine.deviceSerial = userMachine ? userMachine.deviceSerial : "N/A";
        machine.oreTot =
          item.measures.EJ1939_HOURS != null ? item.measures.EJ1939_HOURS.value : 0;
        machine.hIdle =
          item.measures.H_ENGINE_IDLE != null
            ? item.measures.H_ENGINE_IDLE.value / 3600
            : 0;
        machine.hRun =
          item.measures.H_ENGINE_RUN != null
            ? item.measures.H_ENGINE_RUN.value / 3600
            : 0;
        machine.powerOff = machine.oreTot - machine.hRun;
        machine.isStarted = item.measures.ENG_STARTED.value;
        machine.hasOperator = item.measures.SEAT_SWITCH.value;
        machine.measureData = item.measureData;

        //machine positions
        let position = new Object();
        position.id = item.machineId;
        position.name = userMachine ? userMachine.name : "N/A";
        position.position = [item.area.longitude, item.area.latitude];

        this.machinePositions.push(position);
        this.machines.push(machine);
      });
    },

    //TODO: remove
    reloadPage(){
      if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
    }
  },
};
</script>
