import axios from "axios";
import getEnv from "@/utils/env";

const API_URL = getEnv("VUE_APP_USERS_MANAGER_URL") + "auth/";

class AuthService {
  login(user) {
    return axios
      .post(API_URL, {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        let user = response.data;
        sessionStorage.setItem("user", JSON.stringify(user));
        return user;
      });
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
  }

  register(user) {
    return axios.post(API_URL + "signup", {
      username: user.username,
      email: user.email,
      password: user.password,
    });
  }
}

export default new AuthService();
