<template>
  <v-container>
    <row>
      <v-card hover>
        <v-card-title>
          <h2 class="text-red">{{ machine.name }}</h2></v-card-title
        >
        <v-card-text>
          <v-list v-model:opened="open">
            <v-list-group value="Info">
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  prepend-icon="mdi-information"
                  title="Info"
                ></v-list-item>
              </template>
              <v-list-item :title="brand"></v-list-item>
              <v-list-item :title="model" />
              <v-list-item :title="deviceName" />
              <v-list-item :title="deviceSerial" />
            </v-list-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn :color="buttonColor" icon="mdi-arrow-left" @click="goBack" />
          <v-spacer />
          <v-btn
            :color="buttonColor"
            icon="mdi-database"
            @click="goToMeasurePage"
          />
        </v-card-actions>
      </v-card>
    </row>
   
    <v-row>
      <v-col cols="3">
        <VueDatePicker
          v-model="this.startDate"
          enable-seconds
          placeholder="Select Start Date"
          :enable-time-picker="false"
          :format="dateFormat"
        />
      </v-col>
      <v-col cols="3">
        <VueDatePicker
          v-model="endDate"
          enable-seconds
          placeholder="Select End Date"
          :enable-time-picker="false"
          :format="dateFormat"
        />
      </v-col>
      <v-col cols="4">
        <v-btn
          :color="buttonColor"
          class="mr-2"
          @click="setQuarterToSearch(0)"
          >{{ calculateQuarterButton(0) }}</v-btn
        >
        <v-btn
          :color="buttonColor"
          class="mr-2"
          @click="setQuarterToSearch(1)"
          >{{ calculateQuarterButton(1) }}</v-btn
        >
        <v-btn
          :color="buttonColor"
          class="mr-2"
          @click="setQuarterToSearch(2)"
          >{{ calculateQuarterButton(2) }}</v-btn
        >
        <v-btn :color="buttonColor" @click="setQuarterToSearch(3)">{{
          calculateQuarterButton(3)
        }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels multiple v-model="panel">
        <v-expansion-panel title="Machine Status">
          <v-expansion-panel-text>
            <status-graph
              :machineId="machineId"
              :startDate="startDate"
              :end-date="endDate"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel title="Total Fuel Consumed">
          <v-expansion-panel-text>
            <fuel-graph
              :machineId="machineId"
              :startDate="startDate"
              :end-date="endDate"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel title="Averange Travelling Speed">
          <v-expansion-panel-text>
            <speed-graph
              :machineId="machineId"
              :startDate="startDate"
              :end-date="endDate"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import equipmentDataService from "../../services/equipmentData.service";
import FuelGraph from "../equipment/graphs/FuelGraph.vue";
import SpeedGraph from "../equipment/graphs/SpeedGraph.vue";
import StatusGraph from "../equipment/graphs/StatusGraph.vue";

export default {
  name: "MachinePage",
  components: {
    FuelGraph,
    SpeedGraph,
    StatusGraph,
  },
  data() {
    return {
      open: [],
      panel: [0, 1, 2],
      endDate: null,
      startDate: null,
      buttonColor: "indigo-darken-2",
      exportIcon: "mdi-file-excel",
      machine: {},
      dateFormat: "dd/MM/yyyy",
    };
  },
  async mounted() {
    this.machine = await equipmentDataService.getMachineByInfluxId(
      this.machineId
    );

    //console.log("machine", this.machine);
    //set data range iniziale
    this.endDate = new Date();
    this.startDate = new Date();
    let day = this.startDate.getDate() - 7;
    this.startDate.setDate(day);
  },
  props: {
    machineId: { required: true },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goToMeasurePage() {
      this.$router.push({
        name: "machineMeasures",
        params: {
          machine: {
            name: this.machineName,
            id: this.machineId,
          },
        },
      });
    },
    setQuarterToSearch(buttonNumber) {
      const date = this.calculateQuarterDate(buttonNumber);
      const numberOfQuarter = this.calculateQuarter(date);
      const startFullQuarter = new Date(
        date.getFullYear(),
        numberOfQuarter * 3 - 3,
        1
      );
      const endFullQuarter = new Date(
        startFullQuarter.getFullYear(),
        startFullQuarter.getMonth() + 3,
        0
      );
      this.startDate = startFullQuarter;
      this.endDate = endFullQuarter;

      //alert(startFullQuarter.getTime());
    },
    calculateQuarterDate(buttonNumber) {
      const date = new Date();
      const mountsToSubtracts = buttonNumber * 3;
      date.setMonth(date.getMonth() - mountsToSubtracts);
      return date;
    },
    calculateQuarter(date) {
      return Math.floor((date.getMonth() + 3) / 3);
    },
    calculateQuarterButton(buttonNumber) {
      const currentDate = new Date();
      const date = this.calculateQuarterDate(buttonNumber);
      const numberOfQuarter = this.calculateQuarter(date);
      const quarterYear =
        currentDate.getFullYear() == date.getFullYear()
          ? ""
          : "/" + date.getFullYear().toString().substring(2);

      return "Q" + numberOfQuarter + quarterYear;
    },
  },
  computed: {
    model() {
      return "Model: " + this.machine.model;
    },
    brand(){
      return "Brand: " + this.machine.brand;
    },
    deviceSerial(){
      return "Device Serial: " + this.machine.deviceSerial;
    },
    deviceName(){
      return "Device Name: " + this.machine.deviceName;
    },
  },
};
</script>
