<template>
  <div class="d-flex justify-center ma-4">
    <v-col sm="6">
      <v-alert
        type="warning"
        v-model="notActivateUserAlert"
        @click="notActivateUserAlert = false"
      >
        Utente non attivo
      </v-alert>
      <v-alert
        type="error"
        v-model="errorLoginAlert"
        @click="errorLoginAlert = false"
      >
        {{ errorMsg }}
      </v-alert>
    </v-col>
  </div>
  <div class="d-flex align-center justify-center" style="height: 100vh">
    <v-sheet width="400" class="mx-auto">
      <v-card>
        <v-toolbar :color="menuColor" flat>
          <v-toolbar-title>Equipment</v-toolbar-title>
          <v-img
            class="mx-1"
            :src="require('@/assets/est-logo.png')"
            max-height="100"
            max-width="100"
          />
        </v-toolbar>
        <v-card-text>
          <!-- login form -->
          <v-form>
            <v-text-field
              v-model="user.username"
              id="username"
              name="username"
              label="Usename"
              type="text"
              @keyup.enter="handleLogin"
              required
            >
            </v-text-field>
            <v-text-field
              v-model="user.password"
              id="password"
              label="Password"
              type="password"
              @keyup.enter="handleLogin"
              required
            >
            </v-text-field>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-btn
                prepend-icon="mdi-account"
                :color="menuColor"
                @click="login"
                >Login</v-btn
              >
            </v-card-actions>
          </v-form>
          <!-- login form -->
        </v-card-text>
      </v-card>
    </v-sheet>
  </div>
</template>
<script>
export default {
  name: "LoginPage",
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      errorLoginAlert: false,
      errorMsg: "",
      notActivateUserAlert: false,
      menuColor: "indigo-darken-2",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleLogin(e) {
      e.preventDefault();
      this.login();
    },

    async login() {
      try {
        if (
          !this.user.username ||
          !this.user.password ||
          this.user.username === "" ||
          this.user.password === ""
        ) {
          this.showWarning("inserisci username e password");
          return;
        }
        await this.$store.dispatch("auth/login", this.user);
        var loggedUser = this.$store.state.auth.user.user;
        //console.log("LOGIN ------------- loggedUser", loggedUser);
        if (loggedUser.activatedAt) {
          this.$router.push({ path: "/" });
        } else {
          this.notActivateUserAlert = true;
          this.$store.dispatch("auth/logout");
        }
      } catch (e) {
        //console.log("Login error", e, e.data);
        var msg = "";
        if (e.data) {
          msg = ": " + e.data.message;
        }
        this.errorMsg = "Accesso non riuscito" + msg;
        this.errorLoginAlert = true;
      }
    },
  },
};
</script>
