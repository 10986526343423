import axios from "axios";
import getEnv from "@/utils/env";
import authHeader from "./auth-header";

const MACHINE_URL = getEnv("VUE_APP_EQUIPMENT_FLEET_URL") + "machines/";
const COMPANIES_URL = getEnv("VUE_APP_EQUIPMENT_FLEET_URL") + "companies/";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET",
    ...authHeader(),
  },
};

class EquipmentDataService {
  //Machines

  getMachines() {
    return axios
      .get(MACHINE_URL + "getMachines", config)
      .then((response) => {
        // If request is good...
        //console.log("getMachines", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  getAllMachineByOwnerCompanyId(companyId) {
    return axios
      .get(MACHINE_URL + `getAllMachineByOwnerCompanyId/${companyId}`, config)
      .then((response) => {
        // If request is good...
        //console.log("getMachines", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  getAllMachineByDeviceManufacturerCompanyId(companyId) {
    return axios
      .get(
        MACHINE_URL + `getAllMachineByDeviceManufacturerCompanyId/${companyId}`,
        config
      )
      .then((response) => {
        // If request is good...
        //console.log("getMachines", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  saveMachine(machine) {
    let body = JSON.stringify(machine);
    let machineId = machine.id ? machine.id : "";
    return axios
      .post(MACHINE_URL + machineId, body, config)
      .then((response) => {
        // If request is good...
        console.log("saveMachine", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  getMachineById(machineId) {
    return axios
      .get(MACHINE_URL + `${machineId}`, config)
      .then((response) => {
        // If request is good...
        //console.log("getMachineById", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  getMachineByInfluxId(machineInfluxId) {
    return axios
      .get(MACHINE_URL + `getByInfluxID/${machineInfluxId}`, config)
      .then((response) => {
        // If request is good...
        //console.log("getMachineByInfluxId", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  deleteMachine(machineId) {
    return axios
      .delete(MACHINE_URL + `${machineId}`, config)
      .then((response) => {
        // If request is good...
        //console.log("deleteMachine", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  //Companies

  saveCompany(company) {
    let body = JSON.stringify(company);
    let companyId = company.id ? company.id : "";
    return axios
      .post(COMPANIES_URL + companyId, body, config)
      .then((response) => {
        // If request is good...
        //console.log("saveCompany", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  deleteCompany(companyId) {
    return axios
      .delete(COMPANIES_URL + `${companyId}`, config)
      .then((response) => {
        // If request is good...
        //console.log("deleteCompany", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  getCompanies() {
    return axios
      .get(COMPANIES_URL + "getCompanies", config)
      .then((response) => {
        // If request is good...
        //console.log("getCompanies", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  getCompanyById(companyId) {
    return axios
      .get(COMPANIES_URL + `${companyId}`, config)
      .then((response) => {
        // If request is good...
        //console.log("getCompanyById", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  getCompanyByVatnumber(vatNumber) {
    return axios
      .get(COMPANIES_URL + `getByVatnumber/${vatNumber}`, config)
      .then((response) => {
        // If request is good...
        //console.log("getCompanyByVatnumber", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }
}

export default new EquipmentDataService();
