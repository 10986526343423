<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-row> Velocità Media: {{ averangeSpeed }} Km/h </v-row>
        <v-row> Velocità Massima: {{ maxSpeedValue }} Km/h </v-row>
      </v-col>
      <v-col cols="6">
        <apexchart :options="chartOptions" :series="series" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EquipmentService from "../../../services/equipment.service";
import { Measures } from "@/utils/influx";
import { Operations } from "@/utils/influx";
export default {
  name: "SpeedGraph",
  props: {
    machineId: String,
    startDate: Date,
    endDate: Date,
  },

  data() {
    return {
      maxSpeedValue: null,
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                show: true,
              },
              value: {
                fontSize: "22px",
              },
            },
            total: {
              show: true,
              label: "Total",
            },
          },
        },
        labels: ["Speed"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#87D4F9"],
            stops: [0, 100],
          },
        },
      },
    };
  },
  async created() {
    this.maxSpeedValue = await this.getMaxMachineSpeed();
    //console.log("maxSpeedValue", this.maxSpeedValue);

    let result = await this.valueToPercent();
    //console.log("averangeSpeedValue", result);

    this.series.splice(0, 1);
    this.series.push(Math.round(result));
  },

  methods: {
    async valueToPercent() {
      let averange = await this.getAverangeMachineSpeed();
      //console.log("averange", averange);

      if (this.maxSpeedValue == 0 || averange == 0) {
        return 0;
      }

      let result = (averange * 100) / this.maxSpeedValue;
      return result;
    },

    async getMaxMachineSpeed() {
      let queryDTO = {
        measures: [Measures.Speed_Limit],
        tags: {
          machineId: [this.machineId],
        },
        operation: Operations.Last,
      };

      let valueData = await EquipmentService.getData(queryDTO);

      let value =
        !valueData || valueData.data.length == 0
          ? 0
          : valueData.data[0].fields.measureValue;
      //console.log("getMaxMachineSpeed", value);

      return value;
    },

    async getAverangeMachineSpeed() {
      let queryDTO = {
        dataRangeStart: this.startDate,
        dataRangeEnd: this.endDate,
        measures: [Measures.Speed],
        tags: {
          machineId: [this.machineId],
        },
        excludeZero: true,
        operation: Operations.Mean,
      };

      let valueData = await EquipmentService.getData(queryDTO);
      let averangeSpeed =
        !valueData.data || valueData.data.length == 0
          ? 0
          : valueData.data[0].fields.measureValue;
      //console.log("averangeSpeed", averangeSpeed);

      return averangeSpeed;
    },
  },
  computed: {
    changeData() {
      const { startDate, endDate } = this;
      return {
        startDate,
        endDate,
      };
    },
  },
  asyncComputed: {
    async averangeSpeed() {
      let speed = await this.getAverangeMachineSpeed();
      return speed.toFixed(2);
    },
  },
  watch: {
    changeData: {
      deep: true,
      handler: async function () {
        let result = await this.valueToPercent();
        //console.log("Result",result);
        this.series.splice(0, 1);
        this.series.push(Math.round(result));
      },
    },
  },
};
</script>
