import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../components/pages/LoginPage";
import HomePage from "../components/pages/HomePage";
import MachineMeasuresPage from "../components/pages/MachineMeasuresPage";
import MachinePage from "../components/pages/MachinePage";
import UsersPage from "../components/pages/UsersPage";
import ManageUserPage from "../components/pages/ManageUserPage";
import FlottaPage from "../components/pages/FlottaPage";
import ManageFlottaPage from "../components/pages/ManageFlottaPage";
import ManageCompanyPage from "../components/pages/ManageCompanyPage";


import CompaniesPage from "../components/pages/CompaniesPage";

import { Role } from '@/utils/roles';

const routes = [
  {
    path: "/login",
    name: "loginPage",
    component: LoginPage,
  },
  {
    path: "/",
    name: "home",
    component: HomePage,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/machine/:machineId",
    name: "machine",
    component: MachinePage,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/machineMeasures/:machineId",
    name: "machineMeasures",
    component: MachineMeasuresPage,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: "Users",
    component: UsersPage,
    meta: { requiresAuth: true, authorize: [Role.SuperUser] },
  },
  {
    path: "/createUser",
    name: "CreateUser",
    component: ManageUserPage,
    meta: { requiresAuth: true, authorize: [Role.SuperUser] },
  },
  {
    path: "/editUser/:userId",
    name: "EditUser",
    component: ManageUserPage,
    props: true,
    meta: { requiresAuth: true, authorize: [Role.SuperUser] },
  },
  {
    path: "/flotta",
    name: "Flotta",
    component: FlottaPage,
    meta: { requiresAuth: true, authorize: [Role.SuperUser] },
  },
  {
    path: "/createMachine",
    name: "CreateMachine",
    component: ManageFlottaPage,
    meta: { requiresAuth: true, authorize: [Role.SuperUser] },
  },
  {
    path: "/editMachine/:machineId",
    name: "EditMachine",
    component: ManageFlottaPage,
    props: true,
    meta: { requiresAuth: true, authorize: [Role.SuperUser] },
  },
  {
    path: "/companies",
    name: "Companies",
    component: CompaniesPage,
    meta: { requiresAuth: true, authorize: [Role.SuperUser] },
  },
  {
    path: "/createCompany",
    name: "CreateCompany",
    component: ManageCompanyPage,
    meta: { requiresAuth: true, authorize: [Role.SuperUser] },
  },
  {
    path: "/editCompany/:companyId",
    name: "EditCompany",
    component: ManageCompanyPage,
    props: true,
    meta: { requiresAuth: true, authorize: [Role.SuperUser] },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.getItem("user");
  const { authorize } = to.meta;

  if (authorize) {
    //console.log("authorize", authorize);
    let currentUser = JSON.parse(loggedIn);
    if (currentUser && currentUser.user && currentUser.user.groups) {
      let userRoles = currentUser.user.groups.map((gr) => gr.code);
      //console.log("userRoles", userRoles);
      // check if route is restricted by role
      if (authorize.length && !authorize.some(role => userRoles.includes(role))) {
        // role not authorised so redirect to home page
        return next("/");
    }
    }
  }

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
