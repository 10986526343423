import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from "./router/router";
import store from './store';
import VueApexCharts from "vue3-apexcharts";
import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import JsonExcel from "vue-json-excel3";
import AsyncComputed from 'vue-async-computed'
import VueCryptojs from 'vue-cryptojs'

loadFonts()

const app = createApp(App);
app.use(vuetify)
app.use(router)
app.use(store)
app.use(VueApexCharts)
app.use(OpenLayersMap)
app.use(AsyncComputed)
app.use(VueCryptojs)
app.component('VueDatePicker', VueDatePicker);
app.component("downloadExcel", JsonExcel);
app.mount('#app')
