<template>
  <v-app-bar app :color="menuColor" dark>
    <v-app-bar-nav-icon @click="drawer = !drawer" />
    <v-toolbar-title @click="goToHomePage">Equipment</v-toolbar-title>
    <v-img
      class="mx-1"
      :src="require('@/assets/est-logo.png')"
      max-height="150"
      max-width="150"
    />
  </v-app-bar>
  <v-navigation-drawer v-model="drawer">
    <v-list nav dense>
      <v-list-item prepend-icon="mdi-account-check" :title="fullName" nav />
      <v-divider />
      <v-list density="compact" nav>
        <v-list-item title="Home" prepend-icon="mdi-home" to="/" />
        <v-list-item
          title="Users"
          prepend-icon="mdi-clipboard-account"
          to="/users"
          v-show="authorizeRoute"
        />
        <v-list-item
          title="Companies"
          prepend-icon="mdi-city"
          to="/companies"
          v-show="authorizeRoute"
        />
        <v-list-item
          title="Fleet"
          prepend-icon="mdi-forklift"
          to="/flotta"
          v-show="authorizeRoute"
        /> 
      </v-list>
      <v-divider />
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn
          block
          :color="menuColor"
          prepend-icon="mdi-account-arrow-left"
          @click="logOut"
          >Logout</v-btn
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { Role } from "@/utils/roles";
export default {
  data() {
    return {
      authorizedRoles: [Role.SuperUser],
      userRoles: null,
      drawer: false,
      authorizeRoute: null,
      user: {},
      menuColor: "indigo-darken-2",
      customer: process.env.VUE_APP_CUSTOMER,
      loggedUserCompanyRoles: [],
      loggedUserGroups: [],
      userInitials: "",
      loggedUserEnvironment: [],
    };
  },
  computed: {
    fullName: {
      get() {
        return this.user.firstName + " " + this.user.lastName;
      },
      set(newValue) {
        [this.firstName, this.lastName] = newValue.split(" ");
      },
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    this.user = this.$store.state.auth.user.user;
    this.userRoles = this.user.groups.map((gr) => gr.code);
    //console.log("authorizedRoles", this.authorizedRoles);
    //console.log("userRoles", this.userRoles);
    this.authorizeRoute = this.authorizedRoles.some((role) =>
      this.userRoles.includes(role)
    );
    //console.log("authorizeRoute", this.authorizeRoute);
  },

  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    goToHomePage() {
      this.$router.push({
        name: "home",
      });
    },
  },
};
</script>
