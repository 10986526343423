import axios from "axios";
import getEnv from "@/utils/env";

const API_URL = getEnv("VUE_APP_EQUIPMENT_URL");

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "POST,GET",
};

class EquipmentService {
  getData(influxDTO) {
    return axios
      .post(API_URL + "getData", influxDTO, headers)
      .then((response) => {
        // If request is good...
        //console.log("getData", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  getAllMachines() {
    return axios
      .get(API_URL + "getAllMachinesData")
      .then((response) => {
        // If request is good...
        //console.log("getAllMachinesData", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  getSpecificMachinesData(influxDTO) {
    return axios
      .post(API_URL + "getSpecificMachinesData", influxDTO, headers)
      .then((response) => {
        // If request is good...
        //console.log("getSpecificMachinesData: ", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " , error);
      });
  }

  
}

export default new EquipmentService();
