<template>
  <v-row>
    <v-col cols="12">
      <v-switch
        v-model="showMapOption"
        label="Show Map Options"
        color="indigo-darken-2"
        hide-details
      ></v-switch>
    </v-col>
    <v-col cols="12">
      <ol-map
        ref="map"
        :loadTilesWhileAnimating="true"
        :loadTilesWhileInteracting="true"
        style="height: 400px"
      >
        <ol-view
          ref="view"
          :center="center"
          :rotation="rotation"
          :zoom="zoom"
          :projection="projection"
        />

        <ol-fullscreen-control v-if="fullScreenControl" />
        <ol-mouseposition-control v-if="mousePositionControl" />
        <ol-attribution-control v-if="attributionControl" />

        <ol-overviewmap-control v-if="overviewMapControl">
          <ol-tile-layer>
            <ol-source-osm />
          </ol-tile-layer>
        </ol-overviewmap-control>

        <ol-scaleline-control v-if="scaleLineControl" />
        <ol-rotate-control v-if="rotateControl" />
        <ol-zoom-control v-if="zoomControl" />
        <ol-zoomslider-control v-if="zoomSliderControl" />
        <ol-zoomtoextent-control
          v-if="zoomToExtentControl"
          :extent="[23.906, 42.812, 46.934, 34.597]"
          tipLabel="Fit to Turkey"
        />

        <ol-tile-layer>
          <ol-source-osm />
        </ol-tile-layer>

        <div v-for="machine in machinePositions" :key="machine">
          <ol-overlay :position="machine.position">
            <template v-slot="slotProps">
              <v-tooltip :text="machine.name">
                <template v-slot:activator="{ props }">
                  <v-btn
                    color="success"
                    icon="mdi-map-marker-check"
                    size="x-small"
                    v-bind="props"
                    @click="selectMachine(machine, slotProps.position)"
                  />
                </template>
              </v-tooltip>
            </template>
          </ol-overlay>
        </div>
      </ol-map>
    </v-col>
  </v-row>
  <v-row v-if="showMapOption">
    <v-col cols="12" sm="4" md="4">
      <v-checkbox
        v-model="fullScreenControl"
        label="Fullscreen Control"
        color="indigo-darken-3"
      />
      <v-checkbox
        v-model="attributionControl"
        label="Attribution Control"
        color="indigo-darken-3"
      />
      <v-checkbox
        v-model="overviewMapControl"
        label="Overview Map control"
        color="indigo-darken-3"
      />
    </v-col>
    <v-col cols="12" sm="4" md="4">
      <v-checkbox
        v-model="zoomControl"
        label="Zoom Control"
        color="indigo-darken-3"
      />
      <v-checkbox
        v-model="zoomToExtentControl"
        label="Zoom to extent control"
        color="indigo-darken-3"
      />
      <v-checkbox
        v-model="mousePositionControl"
        label="Mouse Position Control"
        color="indigo-darken-3"
      />
    </v-col>
    <v-col cols="12" sm="4" md="4">
      <v-checkbox
        v-model="zoomSliderControl"
        label="Zoom Slider control"
        color="indigo-darken-3"
      />
      <v-checkbox
        v-model="scaleLineControl"
        label="Scale line control"
        color="indigo-darken-3"
      />
      <v-checkbox
        v-model="rotateControl"
        label="Rotate control"
        color="indigo-darken-3"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const center = ref([15.077804, 37.252367]);
    const projection = ref("EPSG:4326");
    const zoom = ref(10);
    const rotation = ref(0);
    const radius = ref(10);
    const strokeWidth = ref(4);
    const strokeColor = ref("red");
    const fillColor = ref("white");
    return {
      center,
      projection,
      zoom,
      rotation,
      radius,
      strokeWidth,
      strokeColor,
      fillColor,
    };
  },
  data() {
    return {
      fullScreenControl: true,
      attributionControl: true,
      zoomControl: true,
      zoomSliderControl: true,
      zoomToExtentControl: true,
      scaleLineControl: true,
      overviewMapControl: true,
      mousePositionControl: true,
      rotateControl: true,
      showMapOption: false,
      machines: [],
    };
  },
  methods: {
    selectMachine(machine, position) {
      console.log("id:" + machine.id + " position" + position);
      this.$router.push("/machine/" + machine.id);
    },
  },
  props: {
    machinePositions: Array,
  },
};
</script>
