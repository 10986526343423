import axios from "axios";
import getEnv from "@/utils/env";
import authHeader from "./auth-header";
import CryptoJS from "crypto-js";

const USER_URL = getEnv("VUE_APP_USERS_MANAGER_URL") + "users/";
const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET",
    ...authHeader(),
  },
};
const reqObj = {
  offset: 0,
  limit: 10000,
  filters: [],
};

class UserService {
  //browse
  browseUsers() {
    return axios
      .post(USER_URL + "browse", reqObj, config)
      .then((response) => {
        // If request is good...
        //console.log("browseUsers", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " + error);
      });
  }

  //manage users

  saveUser(user) {
    let body = JSON.stringify(user);
    let userId = user.id ? user.id : "";
    return axios
      .post(USER_URL + userId, body, config)
      .then((response) => {
        // If request is good...
        //console.log("saveUser", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " + error);
      });
  }

  getUserById(userId) {
    return axios
      .get(USER_URL + `${userId}`, config)
      .then((response) => {
        // If request is good...
        //console.log("getUserById", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " + error);
      });
  }
  getUserByUsername(username) {
    return axios
      .get(USER_URL + `byusername/${username}`, config)
      .then((response) => {
        // If request is good...
        //console.log("getUserById", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " + error);
      });
  }

  deleteUser(userId) {
    return axios
      .delete(USER_URL + `${userId}`, config)
      .then((response) => {
        // If request is good...
        //console.log("deleteUser", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " + error);
      });
  }

  encryptPassword(password) {
    var passwordHash = null;
    var passwordHashAlg = process.env.VUE_APP_PWD_HASHALG;
    //console.log("passwordHashAlg", passwordHashAlg);
    switch (passwordHashAlg) {
      case "MD5":
        passwordHash = CryptoJS.MD5(password);
        break;
      case "SHA256":
        passwordHash = CryptoJS.SHA256(password);
        break;
      default:
        passwordHash = CryptoJS.MD5(password);
    }

    //console.log("passwordHash", passwordHash.toString());
    return passwordHash.toString();
  }

  resetPassword(userId, pwd) {
    let passwordHash = this.encryptPassword(pwd);
    let resetPasswordReq = {
      passwordHash: passwordHash,
      passwordHashAlg: process.env.VUE_APP_PWD_HASHALG,
    };

    //console.log("resetPassword", userId, resetPasswordReq);
    return axios
      .post(USER_URL + `resetPassword/${userId}`, resetPasswordReq, config)
      .then((response) => {
        // If request is good...
        //console.log("resetPassword", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " + error);
      });
  }

  activateUser(userId) {
    return axios
      .post(USER_URL + `setActivationDate/${userId}`, reqObj,config)
      .then((response) => {
        // If request is good...
        //console.log("activateUser", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " + error);
      });
  }

}

export default new UserService();
