<template>
  <v-container>
    <row>
      <v-card hover>
        <v-card-title>
          <h2 class="text-red"> {{ machine.name }}</h2></v-card-title
        >
        <v-card-text>
          <v-list v-model:opened="open">
            <v-list-group value="Info">
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  prepend-icon="mdi-information"
                  title="Info"
                ></v-list-item>
              </template>
              <v-list-item :title="brand"></v-list-item>
              <v-list-item :title="model" />
              <v-list-item :title="deviceName" />
              <v-list-item :title="deviceSerial" />
            </v-list-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn :color="buttonColor" icon="mdi-arrow-left" @click="goBack" />
          <v-spacer />         
        </v-card-actions>
      </v-card>
    </row>
   
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="2">
            <v-card-title class="text-h6 text-md-h5 text-lg-h4"
              >Misure</v-card-title
            >
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searchMeasures"
              append-icon="mdi-magnify"
              label="Cerca"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <download-excel
              class="btn btn-default"
              :data="measuresValueMap"
              :headers="headersMeasuresValue"
              type="xlsx"
              name="measures.xlsx"
            >
              <v-btn :icon="exportIcon" color="green"></v-btn>
            </download-excel>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-data-table
          v-model:items-per-page="itemsPerPage"
          :headers="headersMeasuresValue"
          :items="measuresValueMap"
          :items-per-page="10000"
          :search="searchMeasures"
          hide-default-footer
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="2">
            <v-card-title class="text-h6 text-md-h5 text-lg-h4"
              >Other</v-card-title
            >
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searchStatus"
              append-icon="mdi-magnify"
              label="Cerca"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <download-excel
              class="btn btn-default"
              :data="measuresStatusMap"
              :headers="headersStatusValue"
              type="xlsx"
              name="status.xlsx"
            >
              <v-btn :icon="exportIcon" color="green"></v-btn>
            </download-excel>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-data-table
          v-model:items-per-page="itemsPerPage"
          :headers="headersStatusValue"
          :items="measuresStatusMap"
          :search="searchStatus"
          :items-per-page="10000"
          hide-default-footer
          class="elevation-1"
          @current-items="getFiltered"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { vModelRadio } from "vue";
import EquipmentService from "../../services/equipment.service";
import equipmentDataService from "../../services/equipmentData.service";

export default {
  name: "MachinePage",
  data() {
    return {
      open: [],
      itemsPerPage: 15,
      searchMeasures: "",
      searchStatus: "",
      buttonColor: "indigo-darken-2",
      exportIcon: "mdi-file-excel",
      measures: [],
      machine: {},
    };
  },
  async mounted() {
    this.machine = await equipmentDataService.getMachineByInfluxId(
      this.machineId
    );

    const queryDTO = {
      tags: {
        machineId: [this.machineId],
      },
      operation: "LAST",
    };

    EquipmentService.getData(queryDTO).then((response) => {
      this.measures = response.data;
    });

  },
  props: {
    machineId: { required: true },
  },
  methods: {
    getFiltered: function (value) {
      console.log(value);
    },

    goBack() {
      this.$router.back();
    },
  },
  computed: {
    measuresValueMap() {
      vModelRadio;
      //console.log(this.measures);

      var filteredMeasures = this.measures.filter(function (el) {
        return (
          el.unitOfMeasure != "status" &&
          !isNaN(el.fields.measureValue) &&
          el.fields.measureValue != null
        );
      });

      //console.log("Filter Measures",filteredMeasures);

      let partialArrayItems = filteredMeasures.map((item) => {
        return {
          name: item.name,
          description:
            item.tags.descriptionIT != "null" ? item.tags.descriptionIT : "N/A",
          value: item.fields.measureValue,
          unitOfMeasure:
            item.unitOfMeasure != "null" ? item.unitOfMeasure : "N/A",
        };
      });

      return partialArrayItems;
    },
    headersMeasuresValue() {
      return [
        {
          title: "Misura",
          align: "start",
          sortable: false,
          key: "name",
        },
        { title: "Descrizione", align: "start", key: "description" },
        { title: "Valore", align: "end", key: "value" },
        { title: "Unità di Misura", align: "start", key: "unitOfMeasure" },
      ];
    },

    measuresStatusMap() {
      var filteredMeasures = this.measures.filter(function (el) {
        return (
          el.unitOfMeasure == "status" ||
          isNaN(el.fields.measureValue) ||
          el.fields.measureValue == null
        );
      });
      let partialArrayItems = filteredMeasures.map((item) => {
        let otherValue = "";

        if (item.fields.measureValue == "0") {
          otherValue = "false";
        } else if (item.fields.measureValue == "1") {
          otherValue = "true";
        } else {
          otherValue = item.fields.measureValue;
        }

        return {
          name: item.name,
          description:
            item.tags.descriptionIT != "null" ? item.tags.descriptionIT : "N/A",
          value: otherValue,
        };
      });

      return partialArrayItems;
    },
    headersStatusValue() {
      return [
        {
          title: "Misura",
          align: "start",
          sortable: false,
          key: "name",
        },
        { title: "Descrizione", align: "start", key: "description" },
        { title: "Valore", align: "end", key: "value" },
      ];
    },
    model() {
      return "Model: " + this.machine.model;
    },
    brand(){
      return "Brand: " + this.machine.brand;
    },
    deviceSerial(){
      return "Device Serial: " + this.machine.deviceSerial;
    },
    deviceName(){
      return "Device Name: " + this.machine.deviceName;
    },
  },
 
};
</script>
