<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="text-uppercase">
        <v-row>
          <v-col cols="6">
            {{ machineId ? "Edit machine" : "Create machine" }}
          </v-col>
          <v-col cols="6">
            <v-switch
              v-if="authorizeChange"
              color="info"
              v-model="showInfluxId"
              label="Change Influx Machine"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="editCardClass">
        <v-form ref="manageMachineForm" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="machine.name"
                label="Name"
                maxlength="20"
                :rules="[rules.required, rules.min]"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="machine.brand"
                label="Brand"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="machine.model"
                label="Model"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="selectedOwnedCompany"
                label="Owned Company"
                :items="companies"
                :item-props="companyProps"
                :rules="[rules.required]"
                persistent-hint
                @update:model-value="changeOwnerCompany"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="machine.deviceName"
                label="Device Name"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="machine.deviceSerial"
                label="Device Serial"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="selectedManufacterCompany"
                label="Manufacter Company"
                :items="companies"
                :item-props="companyProps"
                :rules="[rules.required]"
                persistent-hint
                @update:model-value="changeManufacterCompany"
              />
            </v-col>
            <v-col v-if="authorizeChange && showInfluxId" cols="6">
              <v-text-field
                v-model="machine.influxMachineId"
                label="Influx Machine"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="grey darken-1"
          class="pa-2 ma-2"
          @click="discard"
          flat
          >Cancel</v-btn
        >
        <v-btn
          color="success"
          :disabled="!valid"
          class="pa-2 ma-2"
          @click="validate"
          medium
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { Role } from "@/utils/roles";
import equipmentDataService from "../../services/equipmentData.service";

export default {
  name: "ManageFlottaPage",
  title: "ManageFlotta",
  props: {
    machineId: null,
  },
  data() {
    return {
      authorizedRoles: [Role.SuperUser],
      userRoles: null,
      authorizeChange: false,
      showInfluxId: false,
      selectedOwnedCompany: {},
      selectedManufacterCompany: {},
      companies: [],
      machine: {},
      valid: true,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 4) || "Min 4 characters",
      },
    };
  },
  async mounted() {
    this.companies = await equipmentDataService.getCompanies();
    //console.log("companies", this.companies);

    if (this.machineId) {
      this.machine = await equipmentDataService.getMachineById(this.machineId);
      //console.log("machine", this.machine);

      this.selectedOwnedCompany = this.companies.filter((c) => {
        return c.id === this.machine.ownerCompanyId;
      });

      this.selectedManufacterCompany = this.companies.filter((c) => {
        return c.id === this.machine.deviceManufacturerCompanyId;
      });
    }

    let user = this.$store.state.auth.user.user;
    this.userRoles = user.groups.map((gr) => gr.code);
    this.authorizeChange = this.authorizedRoles.some((role) =>
      this.userRoles.includes(role)
    );
  },

  methods: {
    async validate() {
      if (this.$refs.manageMachineForm.validate()) {
        await equipmentDataService.saveMachine(this.machine);
        this.$router.back();
      }
    },
    changeOwnerCompany() {
      this.machine.ownerCompanyId = this.selectedOwnedCompany.id;
      //console.log("machine.ownerCompanyId", this.machine.ownerCompanyId);
    },
    changeManufacterCompany() {
      this.machine.deviceManufacturerCompanyId =
        this.selectedManufacterCompany.id;
      //console.log("machine.deviceManufacturerCompanyId",this.machine.deviceManufacturerCompanyId);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    companyProps(item) {
      return {
        title: item.code,
        subtitle: item.name,
      };
    },
    discard() {
      this.$router.back();
    },
  },
};
</script>
