<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-card-title class="text-h6 text-md-h5 text-lg-h4"
          >Fleet</v-card-title
        >
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cerca"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn color="info" @click="goToAddMachine">
          Add Machine<v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-0">
          <v-data-table
            v-model:items-per-page="itemsPerPage"
            :items="machines"
            :headers="headers"
            :items-per-page="10000"
            :search="search"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <table>
                <tr>
                  <td>
                    <v-btn
                      icon
                      title="Modifica"
                      @click="goToEditMachine(item.raw.id)"
                    >
                      <v-icon color="info">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn icon title="Elimina" @click="doConfirmDelete(item)">
                      <v-icon color="info">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </template>
            <template v-slot:[`item.ownerCompanyId`]="{ item }">
              {{ getCompanyName(item.raw.ownerCompanyId) }}
            </template>
            <template v-slot:[`item.deviceManufacturerCompanyId`]="{ item }">
              {{ getCompanyName(item.raw.deviceManufacturerCompanyId) }}
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="confirmDeleteDialogVisible" max-width="350">
          <v-card>
            <v-card-title
              class="text-uppercase font-weight-regular py-2 my-2 panelheader"
              >Confirm
            </v-card-title>
            <v-card-text class="text-h6">
              Delete machine <b>{{ selectedMachine.name }}</b> ?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="grey darken-1"
                @click="confirmDeleteDialogVisible = false"
                small
                >No</v-btn
              >
              <v-btn color="info" @click="confirmDelete" small>Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import equipmentDataService from "../../services/equipmentData.service";
export default {
  name: "FlottaPage",
  title: "Flotta",
  data() {
    return {
      itemsPerPage: 15,
      search: "",
      companies: [],
      machines: [],
      headers: [
        {
          title: "",
          key: "actions",
          class: "text-uppercase",
          sortable: false,
        },

        { title: "Name", key: "name", class: "text-uppercase" },
        { title: "Brand", key: "brand", class: "text-uppercase" },
        { title: "Model", key: "model", class: "text-uppercase" },
        { title: "Company", key: "ownerCompanyId", class: "text-uppercase" },
        { title: "Device Name", key: "deviceName", class: "text-uppercase" },
        {
          title: "Device Serial",
          key: "deviceSerial",
          class: "text-uppercase",
        },
        {
          title: "Device Company",
          key: "deviceManufacturerCompanyId",
          class: "text-uppercase",
        },
      ],
      selectedMachine: {},
      confirmDeleteDialogVisible: false,
    };
  },

  async mounted() {
    await this.updateMachinesData();
    //console.log("Machines: ", this.machines);
    //console.log("Companies: ", this.companies);
  },
  methods: {
    doConfirmDelete(item) {
      //console.log("item:", item);
      this.selectedMachine = item.raw;
      this.confirmDeleteDialogVisible = true;
    },
    async updateMachinesData() {
      this.machines =  await equipmentDataService.getMachines();

      this.companies =await equipmentDataService.getCompanies();
    },
    async confirmDelete() {
      try {
        //console.log("selectedMachine:", this.selectedMachine);
        await equipmentDataService.deleteMachine(this.selectedMachine.id);
      } catch (ex) {
        console.error("Error delete machine", ex);
      }
      this.confirmDeleteDialogVisible = false;
      await this.updateMachinesData();
    },
    getCompanyName(companyId) {
      var company = this.companies.find((c) => {
        return c.id === companyId;
      });

      if(company){
        return company.name;
      }
      return "N/A";
    },
    goToAddMachine() {
      //console.log("goToAddMachine");
      this.$router.push({ path: "/createMachine" });
      return;
    },
    goToEditMachine(machineId) {
      //console.log("machineId", machineId);
      this.$router.push({ path: "/editMachine/" + machineId });
      return;
    },
  },
};
</script>
