<template>
  <v-sheet class="mt-5 mx-auto" elevation="10" max-width="100%">
    <v-slide-group 
    mobile-break-point="1000" show-arrows center-active>
      <template v-slot:next>
        <v-icon :color="color" large>{{ iconRight }}</v-icon>
      </template>
      <template v-slot:prev>
        <v-icon :color="color" large>{{ iconLeft }}</v-icon>
      </template>
      <v-slide-group-item v-for="machine in machines" :key="machine">
        <machine-card :machine="machine" />
      </v-slide-group-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
import MachineCard from "./MachineCard.vue";

export default {
  name: "MachineSlide",
  data() {
    return {
      color: "blue",
      iconRight: "mdi-arrow-right-circle",
      iconLeft: "mdi-arrow-left-circle",
    };
  },
  components: {
    MachineCard,
  },
  props: {
    machines: Array,
  },
};
</script>
