<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6 offset-3">
        <apexchart :options="chartOptions" :series="series" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EquipmentService from "../../../services/equipment.service";
import { Measures } from "@/utils/influx";
import { Operations } from "@/utils/influx";

export default {
  name: "FuelGraph",
  props: {
    machineId: String,
    startDate: Date,
    endDate: Date,
  },
  data() {
    return {
      series: [
        {
          name: "Consumo medio (Litri/Ora)",
          data: [],
        },
        {
          name: "Tot Consumo (Litri)",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false, // <== line to add
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Fuel"],
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
    };
  },
  async created() {
    await this.updateChartData();
  },
  methods: {
    async getTotalMachineFuel() {
      let maxTotalFuel = await this.getMaxTotalMachineFuel();
      let minTotalFuel = await this.getMinTotalMachineFuel();

      let totalFuelResult = maxTotalFuel - minTotalFuel;
      //console.log("totalFuelResult", totalFuelResult);

      return totalFuelResult;
    },

    async getMaxTotalMachineFuel() {
      let queryDTO = {
        dataRangeStart: this.startDate,
        dataRangeEnd: this.endDate,
        measures: [Measures.Total_Fuel],
        tags: {
          machineId: [this.machineId],
        },
        excludeZero: true,
        operation: Operations.Max,
      };

      let valueData = await EquipmentService.getData(queryDTO);
      let value =
        valueData.data.length == 0 ? 0 : valueData.data[0].fields.measureValue;

      //console.log("getMaxTotalMachineFuel", value);

      return value;
    },

    async getMinTotalMachineFuel() {
      let queryDTO = {
        dataRangeStart: this.startDate,
        dataRangeEnd: this.endDate,
        measures: [Measures.Total_Fuel],
        tags: {
          machineId: [this.machineId],
        },
        excludeZero: true,
        operation: Operations.Min,
      };

      let valueData = await EquipmentService.getData(queryDTO);
      let value =
        valueData.data.length == 0 ? 0 : valueData.data[0].fields.measureValue;

      //console.log("getMinTotalMachineFuel", value);

      return value;
    },

    async getAverangeMachineConsume() {
      let queryDTO = {
        dataRangeStart: this.startDate,
        dataRangeEnd: this.endDate,
        measures: [Measures.Fuel_Rate],
        tags: {
          machineId: [this.machineId],
        },
        operation: Operations.Mean,
      };

      return EquipmentService.getData(queryDTO).then((response) => {
        if (response.data.length == 0) {
          return 0;
        }
        return response.data[0].fields.measureValue;
      });
    },
    async updateChartData() {
      let totalFuel = await this.getTotalMachineFuel();
      //console.log("totalFuel", totalFuel);

      //consumo totale
      this.series[1].data.splice(0, 1);
      this.series[1].data.push(Math.round(totalFuel));

      let averangeConsume = await this.getAverangeMachineConsume(); //l/h
      //console.log("averangeConsume", averangeConsume);

      //consumo instantaneo
      this.series[0].data.splice(0, 1);
      this.series[0].data.push(Math.round(averangeConsume));
    },
  },
  computed: {
    changeData() {
      const { startDate, endDate } = this;
      return {
        startDate,
        endDate,
      };
    },
  },
  watch: {
    changeData: {
      deep: true,
      handler: async function () {
        await this.updateChartData();
      },
    },
  },
};
</script>
