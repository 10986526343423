<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="text-uppercase">
        <v-row>
          <v-col cols="6">
            {{ userId ? "Edit user" : "Create user" }}
          </v-col>
          <v-col cols="6">
            <v-switch v-if="userId" color="info" v-model="showChangePassword" label="Change Password"></v-switch>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="editCardClass">
        <v-form ref="manageUserForm" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="user.firstName"
                :rules="[rules.required]"
                label="First Name"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="user.lastName"
                :rules="[rules.required]"
                label="Last Name"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="user.username"
                :rules="[rules.required]"
                label="userName"
                maxlength="20"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="selectedCompany"
                label="Company"
                :items="companies"
                :item-props="companyProps"
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="selectedGroups"
                :items="groups"
                :item-props="groupProps"
                label="Groups"
                multiple
                persistent-hint
              />
            </v-col>
            <v-col v-if="!user.id || showChangePassword" cols="6">
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                hint="At least 8 characters"
                counter
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-col>
            <v-col v-if="!user.id || showChangePassword" cols="6">
              <v-text-field
                block
                v-model="verify"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, passwordMatch]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-2"
                label="Confirm Password"
                counter
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          color="grey darken-1"
          class="pa-2 ma-2"
          @click="discard"
          flat
          >Cancel</v-btn
        >
        <v-btn
          color="success"
          :disabled="!valid"
          class="pa-2 ma-2"
          @click="validate"
          medium
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import usersService from "../../services/user.service";
import groupService from "../../services/group.service";
import companyService from "../../services/company.service";

export default {
  name: "ManageUserPage",
  title: "ManageUser",
  props: {
    userId: null,
  },
  data() {
    return {
      showChangePassword:false,
      groups: [],
      selectedGroups: [],
      selectedCompany: {},
      companies: [],
      user: {},
      password: null,
      verify: "",
      valid: true,
      emailRules: [
        (v) => !!v || "Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      show1: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
    };
  },
  async mounted() {
    if (this.userId) {
      await usersService.getUserById(this.userId).then((response) => {
        this.user = response.data;
      });
      //console.log("user", this.user);

      this.selectedGroups = this.user.groups;
      this.selectedCompany = this.user.company;
    }

    await groupService.browseGroups().then((response) => {
      this.groups = response.data.resultList;
    });
    //console.log("groups", this.groups);

    await companyService.browseCompanies().then((response) => {
      this.companies = response.data.resultList;
    });
    //console.log("companies", this.companies);
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
  },
  methods: {
    async validate() {
      if (this.$refs.manageUserForm.validate()) {
        //console.log("selectedCompany:", this.selectedCompany);
        this.user.company = this.selectedCompany;
        this.user.companyId = this.selectedCompany.id;
        this.user.groups = this.selectedGroups;
        //console.log("user:", this.user);
        await usersService.saveUser(this.user).then((response) => {
          this.user = response.data;
        });

        if (this.password && this.user) {
          await usersService.resetPassword(this.user.id, this.password);
        }

        this.$router.back();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    groupProps(item) {
      return {
        title: item.code,
        subtitle: item.description,
      };
    },
    companyProps(item) {
      return {
        title: item.code,
        subtitle: item.name,
      };
    },
    discard() {
      this.$router.back();
    },
  },
};
</script>
