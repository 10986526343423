import axios from "axios";
import getEnv from "@/utils/env";
import authHeader from "./auth-header";


const GROUPS_URL = getEnv("VUE_APP_USERS_MANAGER_URL") + "groups/";
const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET",
    ...authHeader(),
  },
};
const reqObj = {
  offset: 0,
  limit: 10000,
  filters: [],
};

class GroupService {

  browseGroups() {
    return axios
      .post(GROUPS_URL + "browse", reqObj, config)
      .then((response) => {
        // If request is good...
        //console.log("browseGroups", response.data);
        return response;
      })
      .catch((error) => {
        console.error("error " + error);
      });
  }
  getGroupyId(groupId) {
    return axios
    .get(GROUPS_URL + `${groupId}`, config)
    .then((response) => {
      // If request is good...
      //console.log("getGroupyId", response.data);
      return response;
    })
    .catch((error) => {
      console.error("error " + error);
    });
  }
  getGroupByCode(code) {
    return axios
    .get(GROUPS_URL + `getByCode/${code}`, config)
    .then((response) => {
      // If request is good...
      //console.log("getGroupByCode", response.data);
      return response;
    })
    .catch((error) => {
      console.error("error " + error);
    });
  }




}

export default new GroupService();
