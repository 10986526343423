export const Measures = {
    Total_Fuel: "EJ1939_FUSED",
    Fuel_Rate: "EJ1939_FRATE",
    Speed_Limit: "ACT_VSPEED_LIM",
    Speed: "VSPEED",
    Engine_Hours: "EJ1939_HOURS",
    Engine_Idle_Hours: "H_ENGINE_IDLE",
    Engine_Run_Hours: "H_ENGINE_RUN",
    Longitude: "LONGITUDE",
    Latitude: "LATITUDE",
    Engine_Started: "ENG_STARTED",
    Operator_Presence: "SEAT_SWITCH",
}

export const Operations = {
    Mean: "MEAN",
    Last: "LAST",
    First: "FIRST",
    Min: "MIN",
    Max:"MAX",
    Count:"COUNT",
    Sum:"SUM"
}
