<template>
  <v-app>
    <navigation-bar v-if="loggedIn"/>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import NavigationBar from './components/nav/NavigationBar.vue'

export default {
  name: 'App',
  components: {
    NavigationBar
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
}
</script>


